/** Colors variables */
/** share button variables */
/** share buttons colors */
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-group,
.sb-button {
  display: inline-flex;
  align-items: flex-start;
}

.sb-group {
  flex-wrap: wrap;
}

.sb-button {
  margin: var(--sb-margin);
}

/** Default style */
.sb-wrapper {
  font-size: inherit;
  cursor: pointer;
  position: relative;
  outline: 0;
  min-width: 4.125em;
  height: 2.5em;
  border: none;
  border-radius: 1px;
  padding: 0;
  line-height: 2.571em;
  background-color: transparent;
  -webkit-print-color-adjust: exact;
}
.sb-wrapper .sb-icon,
.sb-wrapper .sb-text {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.sb-wrapper .sb-content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}
.sb-wrapper .sb-text {
  flex: 1;
  height: 100%;
  white-space: nowrap;
  padding: 0 0.7em;
}
.sb-wrapper .sb-icon {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  min-width: 2em;
}
.sb-wrapper .sb-text {
  font-weight: bold;
}

.sb-circles-light .sb-wrapper {
  color: #2E4057;
  background: transparent;
  min-width: unset;
  height: unset;
}
.sb-circles-light .sb-wrapper.sb-facebook .sb-icon,
.sb-circles-light .sb-wrapper.sb-facebook .sb-text {
  color: #4267B2;
}
.sb-circles-light .sb-wrapper.sb-twitter .sb-icon,
.sb-circles-light .sb-wrapper.sb-twitter .sb-text {
  color: #00acee;
}
.sb-circles-light .sb-wrapper.sb-google .sb-icon,
.sb-circles-light .sb-wrapper.sb-google .sb-text {
  color: #db4437;
}
.sb-circles-light .sb-wrapper.sb-mix .sb-icon,
.sb-circles-light .sb-wrapper.sb-mix .sb-text {
  color: #ff8226;
}
.sb-circles-light .sb-wrapper.sb-line .sb-icon,
.sb-circles-light .sb-wrapper.sb-line .sb-text {
  color: #00b900;
}
.sb-circles-light .sb-wrapper.sb-linkedin .sb-icon,
.sb-circles-light .sb-wrapper.sb-linkedin .sb-text {
  color: #006fa6;
}
.sb-circles-light .sb-wrapper.sb-pinterest .sb-icon,
.sb-circles-light .sb-wrapper.sb-pinterest .sb-text {
  color: #bd081c;
}
.sb-circles-light .sb-wrapper.sb-reddit .sb-icon,
.sb-circles-light .sb-wrapper.sb-reddit .sb-text {
  color: #ff4006;
}
.sb-circles-light .sb-wrapper.sb-tumblr .sb-icon,
.sb-circles-light .sb-wrapper.sb-tumblr .sb-text {
  color: #36465d;
}
.sb-circles-light .sb-wrapper.sb-whatsapp .sb-icon,
.sb-circles-light .sb-wrapper.sb-whatsapp .sb-text {
  color: #25d366;
}
.sb-circles-light .sb-wrapper.sb-messenger .sb-icon,
.sb-circles-light .sb-wrapper.sb-messenger .sb-text {
  color: #0080FF;
}
.sb-circles-light .sb-wrapper.sb-telegram .sb-icon,
.sb-circles-light .sb-wrapper.sb-telegram .sb-text {
  color: #0088cc;
}
.sb-circles-light .sb-wrapper.sb-xing .sb-icon,
.sb-circles-light .sb-wrapper.sb-xing .sb-text {
  color: #006567;
}
.sb-circles-light .sb-wrapper.sb-sms .sb-icon,
.sb-circles-light .sb-wrapper.sb-sms .sb-text {
  color: #20c16c;
}
.sb-circles-light .sb-wrapper.sb-email .sb-icon,
.sb-circles-light .sb-wrapper.sb-email .sb-text {
  color: #FF961C;
}
.sb-circles-light .sb-wrapper.sb-viber .sb-icon,
.sb-circles-light .sb-wrapper.sb-viber .sb-text {
  color: #665ca7;
}
.sb-circles-light .sb-wrapper.sb-vk .sb-icon,
.sb-circles-light .sb-wrapper.sb-vk .sb-text {
  color: #4C75A3;
}
.sb-circles-light .sb-wrapper.sb-copy .sb-icon,
.sb-circles-light .sb-wrapper.sb-copy .sb-text {
  color: #607D8B;
}
.sb-circles-light .sb-wrapper.sb-print .sb-icon,
.sb-circles-light .sb-wrapper.sb-print .sb-text {
  color: #765AA2;
}
.sb-circles-light .sb-wrapper.sb-expand .sb-icon,
.sb-circles-light .sb-wrapper.sb-expand .sb-text {
  color: #FF6651;
}
.sb-circles-light .sb-wrapper:active .sb-icon {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.sb-circles-light .sb-wrapper .sb-icon, .sb-circles-light .sb-wrapper .sb-text {
  color: var(--button-color) !important;
}
.sb-circles-light .sb-wrapper .sb-icon {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  font-size: 1.4em;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background: radial-gradient(ellipse farthest-corner at center, white 30%, #f2f2f2 100%);
}
.sb-circles-light .sb-wrapper .sb-text {
  font-size: 0.8em;
  margin-top: 5px;
  width: 100%;
  height: 1.8em;
  line-height: 1.8em;
  padding: 0;
}
.sb-circles-light .sb-wrapper .sb-content {
  align-items: center;
  flex-direction: column;
}